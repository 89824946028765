
function Footer() {
    return(
        <div style={{height:"50px",marginTop:"30px"}}>
            <a href="https://customeraization.tech/" style={{fontFamily:"Arial, sans-serif"}}>
            <hr style={{height:"2px",width:"100%",borderWidth:"0px",backgroundColor:"grey"}}/>
            ©2024 3C CUSTOMERAIZATION TECHNOLOGIES PRIVATE LTD | All Rights Reserved</a></div>
    );
}

export default Footer;